<template>
  <VAppBar
    class="rounded-b-lg"
    color="#0025ff"
    v-bind="$attrs"
  >
    <slot
      v-for="(_, name) in $slots"
      :name="name"
    />
    <template
      v-for="(_, name) in $scopedSlots"
      #[name]="slotData"
    >
      <slot
        :name="name"
        v-bind="slotData"
      />
    </template>
  </VAppBar>
</template>

<script>
export default {
  name: 'AppBarMobile',
};
</script>

<style scoped>

</style>
