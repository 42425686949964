<template>
  <VContainer class="pa-0">
    <VTabs
      :value="value"
      v-bind="{...$props,...$attrs}"
      :color="$attrs['color'] || 'black'"
      :class="{'tabs-bar' : true,'tabs-bar--underline' : isToolbarUnderlined}"
      :background-color="$attrs['background-color'] || 'transparent'"
      data-test="tab-items"
      :show-arrows="true"
      @change="onChange"
    >
      <div
        v-for="tab in tabs"
        :key="tab.text"
        class="tabs-bar-tab"
        :class="{'tabs-bar-tab--underline' : isTabUnderlined}"
      >
        <slot name="tab">
          <VTab
            :ripple="false"
            :to="`#${tab.value}`"
            :class="generatedTabStyle"
            :data-test="tab.dataTest || `tab-` + tab.value"
          >
            {{ tab.text }}
          </VTab>
        </slot>
      </div>
    </VTabs>
    <slot />
  </VContainer>
</template>

<script>
export default {
  name: 'TabsWrapper',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Object],
      required: true,
    },
    underline: {
      type: String,
      validator: (val) => ['tab', 'toolbar', 'none'].includes(val),
      default: () => 'tab',
    },
    tabs: {
      type: [Array],
      default: () => [],
    },
    textColor: {
      type: String,
      default: 'tt-light-mono-46--text',
    },
  },
  computed: {
    isToolbarUnderlined() {
      return this.underline === 'toolbar';
    },
    isTabUnderlined() {
      return this.underline === 'tab';
    },
    generatedTabStyle() {
      return `justify-start px-0 tabs-bar-tab-hover--none
       tt-text-caption fill-height align-start text-transform--none`;
    },
  },
  methods: {
    onChange(val) {
      this.$emit('change', val);
    },
  },
};
</script>

<style scoped>

</style>
